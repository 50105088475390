.badge-warning {
    color: #fff;
    background-color: orange;
}
.badge {
    font-weight: 600 !important;
    line-height: 1.4 !important;
    padding: 3px 6px !important;
    font-size: 12px !important;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    vertical-align: baseline;
    border-radius: 0.25rem !important;
}
.annualTitles {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0px;
    margin-bottom: 0;
    color: #515365;
}