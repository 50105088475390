.textLabel{
    color: #676767;
    font-size: 15px;
    font-weight: bold;
    margin: 5px 10px 8px 0;
    vertical-align: top;
    font-family: 'Poppins', sans-serif;
}
.textContent{
    margin: 5px 10px 8px 0;
    vertical-align: top;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
}
.msgbox{
    margin-top: 10px;
    border-top: 1px solid rgb(38 0 249 / 0.2);
}