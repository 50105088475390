:where(.css-dev-only-do-not-override-mxhywb).ant-modal .ant-modal-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 20px;
    line-height: 1.5;
    word-wrap: break-word;
}
:where(.css-dev-only-do-not-override-mxhywb).ant-modal .ant-modal-body {
    font-size: 16px;
    line-height: 1.5714285714285714;
    word-wrap: break-word;
    color:"#2a40af"
}
.forget-form-box {
    border-radius: 10px;
    position: relative;
}
.forget-page-style input {
    background: #f5f5f5;
    border: 1px solid transparent;
    border-radius: 5px;
    color: #6f6b80;
    color: var(--color-body);
    font-weight: 500;
    height: 50px;
    padding: 0 10px;
}
a.edu-btn1, button.edu-btn1 {
    background: #525fe1;
    border: 0;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    width: auto;
}

.spin-loader-2{
    position: absolute;
    left: 30%;
    top: -20%;
}

.success.d-block{
    color: #17a12e;
}