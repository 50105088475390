.ts_Para {
    margin-top: 0;
    color: #515365;
    font-size: 0.875rem;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    font-weight: 400;
}

.item-content {
    background-color: #fff;
    padding: 13px 18px;
    box-shadow: 0px 0px 4px 1px rgba(126, 142, 177, 0.12);
    -webkit-box-shadow: 0px 0px 8px 2px rgba(126, 142, 177, 0.22);
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.user-name {
    margin-top: 0;
    margin-bottom: 0.625rem;
    color: #515365;
    font-size: 0.875rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.5;
}

.carousel-captions {
    position: absolute;
    /* right: 15%; */
    /* bottom: 20px; */
    /* left: 19%; */
    /* margin-left: 4.5%; */
    top:20%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

.carousel-titles {
    /* background: rgba(0, 0, 0, .6); */
    color: white;
    text-align: center !important;
    padding: 5px;
    border-radius: 10px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.40) !important;
    background-color: #1b4d70 !important;
    color: white !important;
    text-align: center;
}

.rounded-corner-pills-icon .nav-pills li a:hover {
    background-color: #d8d8d8;
    transition: all ease 0.5s;
}

.rounded-corner-pills-icon .nav-pills li a {
    border-radius: 0.5rem !important;
    background-color: #f1f2f3;
    width: 100px;
    padding: 8px;
    transition: all ease 0.5s;
    color: #3b3f5c !important;
    text-align: center;
}

.pillsIcon {
    display: block;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
}

.annualTitles {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0px;
    margin-bottom: 0;
    color: #515365;
}
.btn-primarys {
    color: #fff !important;
    background-color:#bbce00 !important;
    border-color:#bbce00;
}
.numberCircle {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 3px;
    background: #fff;
    /* border: 2px solid #666; */
    color: #ffffff;
    text-align: center;
    background-color: #bbce00;
    font-size: 12px;
    /* font: 32px Arial, sans-serif; */

}
select.selectForm.form-select {
    width: 97% !important;
    color: #2f44b2 !important;
    font-size: 14px !important;
}
td.ant-table-cell {
    font-family: 'Poppins', sans-serif !important;
}