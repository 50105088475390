.inputFields {
    display: none;
}

.custom-file-upload {
    border-radius: 50%;
    display: inline-block;
    position: relative;
    padding: 6px;
    cursor: pointer;
    background: linear-gradient(270deg, #3fa1a9, #79f1a4);
    margin-bottom: 10px;
}

.img-wrap {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
}

.img-upload:before {
    content: url(../img/uploads.png);
    /* font-size: 90px; */
    position: absolute;
    background-color: #fff;
    width: 20px;
    opacity: 0;
    width: 100px;
    height: 100px;
    transition: .5s ease;
}
  
.img-upload:hover:before {
    opacity: 1;
}
/* 
.profileimg {
    width: 100%;
    height: 100%;
} */
.img-wrap img{
    width: 100%;
    height: 100%;
}
.uploadText{
    font-size: 14px;
    font-family: 'Poppins', sans-serif !important;
}
.imagesProfile{
    width: 100%;
}