.profile-info {
    box-shadow: 1px 1px 20px rgb(0 0 0 / 17%);
    padding: 20px 20px;
    border-radius: 10px;
    background: #ffffff;

}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Poppins', sans-serif;
}

.profile-left {
    box-shadow: 1px 1px 20px rgb(0 0 0 / 17%);
    padding: 20px 20px;
    border-radius: 10px;
    background: white;
}

.rounded-circle {
    border-radius: 50% !important;
    width: 120px;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;

}

.personName {
    margin-top: 0;
    margin-bottom: 0.625rem;
    color: #515365;
}

.info-area {
    text-align: center;
    margin-top: 14px;
}

.info-area h6 {
    text-align: center;
    letter-spacing: 0px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 3px;
    color: #3b3f5c;
}

.video {
    width: 100%;
    height: 445px
}

@media (max-width: 767px) {
    .video {
        width: 100%;
        height: 200px
    }
}

.scrollbarsvideo{
    height: 115vh;
    /* overflow-y: scroll; */
    overflow-x: hidden;
    /* padding-bottom: 4rem; */
}
::-webkit-scrollbarsvideo {
    width: 0px;
}

/* Track */
::-webkit-scrollbarsvideo-track {
    background: #f1f1f1;
}
.annualTitles {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0px;
    margin-bottom: 0;
    color: #515365;
}
::-webkit-scrollbarsvideo-thumb {
    background:rgb(163, 162, 162);
}

::-webkit-scrollbarsvideo-thumb:hover {
    background: rgb(146, 143, 143);
}
.teamscrollbars{
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
::-webkit-teamscrollbars {
    width: 0px;
}

/* Track */
::-webkit-teamscrollbars-track {
    background: #f1f1f1;
}

::-webkit-teamscrollbars-thumb {
    background:rgb(163, 162, 162);
}

::-webkit-teamscrollbars-thumb:hover {
    background: rgb(146, 143, 143);
}
.arrowdown{
    font-size: 20px;
}
.arrowUp{
    font-size: 20px;
}